<template>
   <div class="unit_info">
        <div class="notice">
            <div class="notice_top">
                <img src="http://t.6a.com/94,011a48df4a5c6191.png" alt="" />
            </div>
            <div class="info_name">{{name}}</div>
            <div class="info_item">
                <img src="../assets/img/qk.png" alt="">
                <div>珍宝编码：</div>
                <div>{{serial_number}}</div>
            </div>
            <div class="info_item">
                <img src="../assets/img/dj.png" alt="">
                <div>珍宝等级：</div>
                <div>{{level}}</div>
            </div>
            <img  :src="pictures" alt="">
            <div class="btn" @click="submint">提交申请</div>
            <div class="tips">
                注意：联名申请需要等待珍宝创建者确认，成功联名后，您可以在"珍宝管理页面"查看
            </div>
        </div>
   </div>
</template>

<script>
export default {
    data(){
        return{
            name:'',
            serial_number:'',
            pictures:'',
            level:'',
            msg:''
        }
    },
    async mounted(){
        await this.axios.get('/auth/nt?code='+this.$route.query.code).then((res)=>{
          window.localStorage.setItem('user_token',res.data.data.token)
          token = localStorage.getItem('user_token')
        }).catch(() => {})
        this.axios.get(`goods/getInviteLinkApplyInfoXcx?qrCode=${this.$route.query.qrCode}&artist_type=1`).then((res)=>{
            if(res.data.code == 0){
                this.name = res.data.data.goods.name
                this.serial_number = res.data.data.goods.serial_number
                this.pictures = res.data.data.goods.pictures[0]
                this.level = res.data.data.goods.level.name
                this.goods_id = res.data.data.goods.id

            }else{
                this.msg = res.data.msg
                if(res.data.msg == '您不能申请成为自己作品的联名作者'){
                    weui.alert(res.data.msg)
                    return
                }
                weui.alert('二维码已过期，请重新扫码')
            }
        }).catch(err=>{
            weui.alert('二维码已过期，请重新扫码')
        })
    },
    methods:{
        submint(){
            if(!this.goods_id){
                weui.alert(this.msg)
                return
            }
            this.axios.post('goods/inviteLinkApplySubmit',{goods_id:this.goods_id,qrCode:this.$route.query.qrCode,artist_type:1}).then((res)=>{
                if(res.data.code == 0){
                    weui.alert('提交成功',()=>{
                        this.$router.replace('/home')
                    })
                }else if(res.data.msg == '请输入手机号'){

                }else{
                    weui.alert(res.data.msg)
                }
            })
        }
    }
}
</script>

<style scoped>
.unit_info{
    background: #450202;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.notice{
    width: 3.47rem;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-top: 0.15rem;
    padding-bottom: 0.25rem;
    background: url(../assets/img/notice.png) no-repeat;
    background-size: 100% 100%;
    color: #ffedaa;
}
.notice_top{
    flex-shrink: 0;
    height: 0.35rem;
    display: flex;
    flex-direction: column;
    padding: 0 0.32rem;
    width: 100%;
    box-sizing: border-box;
}
.notice_top img{
    width: 0.88rem;
    height: 0.33rem;
    object-fit: contain;
}
.info_name{
    height: 0.36rem;
    width: 2.7rem;
    font-size: 0.15rem;
    line-height: 0.18rem;
    color: #fee9be;
    margin: 0.15rem 0;
}
.info_item{
    width: 3.12rem;
    display: flex;
    align-items: center;
    font-size: 0.13rem;
    margin-bottom: 0.09rem;
}
.info_item img{
    width: 0.12rem;
    height: 0.1rem;
    margin-right: 0.1rem;
}
.info_item div:nth-child(3){
    margin-left: auto;
    
}
.notice > img{
    width: 3.1rem;
    height: 3.1rem;
    object-fit: contain;
}
.btn{
    margin-top: 0.15rem;
    background: url(../assets/img/view-more.png)no-repeat;
    width: 2.55rem;
    height: 0.4rem;
    font-size: 0.14rem;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
}
.tips{
    width: 2.95rem;
    font-size: 0.12rem;
    color: #fff;
    margin-top: 0.2rem;
}
</style>